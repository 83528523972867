<template>
  <div>
    <div class="content-box">
      <div class="content-main">
        <el-form
          :model="form"
          :rules="rules"
          ref="form"
          :inline="true"
          :label-position="$config.labelPosition"
          :label-width="$config.labelWidth"
        >
          <el-row :gutter="10">
            <!-- 基本信息 -->
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="养护企业" prop="careUnitName">
                <el-select v-model="form.careUnitName" placeholder="请选择">
                  <el-option
                    v-for="item in careUnitNameOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="标段名称" prop="bidId">
                <el-cascader
                  placeholder="请选择"
                  v-model="form.bidId"
                  :options="bidOptions"
                  @change="handleBidChage"
                  clearable
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-form-item label="备注">
                <el-input
                  v-model="form.remark"
                  type="textarea"
                  :autosize="{minRows:4}"
                  placeholder="请输入"
                  maxlength="25"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-form-item label="培训内容资料">
                <ayl-upload-files v-model="resourceKey" :limit="3" :maxSize="10240" :accept="''"></ayl-upload-files>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="tac" style="margin-top: 40px">
        <el-button type="success" class="btn-success-hw" @click="onSubmit">提交</el-button>
        <el-button type="danger" class="btn-danger" style="margin-left: 10px" @click="onClose">关 闭</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import addForDialog from "@/mixins/add-for-dialog";
export default {
  mixins: [addForDialog],
  data() {
    return {
      nav: [{ name: "新增应急预案" }],
      careUnitNameOption: [],
      submitLoading: false,
      form: {
        careUnitName: null, //养护企业
        bidId: null, //标段名称
        remark: null, //备注
        resourceKey: [] //上传培训内容资料
      },
      resourceKey: [],
      // 必填字段校验
      rules: {
        careUnitName: {
          required: true,
          message: "请输入养护企业",
          trigger: "change"
        },
        bidId: [
          {
            required: true,
            message: "请输入标段",
            trigger: "change"
          }
        ]
      }
    };
  },
  async mounted() {
    // 获取养护
    await this.$api_hw.districtInfo_getDistinctCareUnit({}).then(res => {
      this.careUnitNameOption = res.map(item => {
        return { value: item, label: item };
      });
    });
  },
  methods: {
    async submit() {
      let formData = JSON.parse(JSON.stringify(this.form));
      formData.resourceKey = this.resourceKey.map(item => {
        return JSON.stringify(item);
      });
      await this.$api_hw
        .operationSafetyManagement_emergencyPlanInsert({ record: formData })
        .then(res => {
          BUS.$emit(BUSEVENT.REFRESH_EMERGENCY_MANAGEMENT1);
        });
    }
  }
};
</script>

<style lang='sass' scoped>
.content-box .content-main
  padding: 0 20px 20px
.content-box 
  padding: 0
/deep/ el-form
  display: flex;
  flex-wrap: wrap;
/deep/ .el-form-item
  display: flex;
  width: 100%;
/deep/ .el-date-editor.el-input,
/deep/ .el-range-editor--mini.el-input__inner,
/deep/ .el-cascader--mini,
/deep/ .el-select
  width: 100%;
/deep/ .el-form-item--mini .el-form-item__content
  flex: 1;
/deep/ .el-form-item--mini .el-form-item__label 
  text-align: right;
  width: 130px;
</style>